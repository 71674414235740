import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  position: relative; /* 상대적 위치 설정 */
`;

export const ConnectWalletDiv = styled.div`
  position: absolute;
  top: 20px; /* 상단 20px 위치 */
  right: 20px; /* 오른쪽 20px 위치 */
  z-index: 10; /* 다른 요소들 위에 위치하도록 설정 */
`;

// 로고 스타일
export const Logo = styled.img`
  width: 320px;
  height: 120px;
  margin-bottom: 30px; /* 로고와 텍스트 사이 간격 */
  object-fit: contain;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  border-radius: 10px; /* 둥근 모서리 */
`;

// 메인 텍스트 스타일 (개발 단계 강조)
export const Text = styled.p`
  margin-top: 40px;
  color: #2f4f4f; /* Dark Slate Gray 색상 */
  font-size: 56px;
  font-weight: 600; /* 굵은 글씨 */
  line-height: 1.4;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* 텍스트 그림자 */
`;

// 서브 텍스트 스타일 (부드러운 느낌)
export const SubText = styled.p`
  color: #333;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 15px;
  font-family: 'Arial', sans-serif; /* 기본 폰트 */
  opacity: 0.8; /* 텍스트 투명도 */
`;

// 개발 단계 배너
export const DevNotice = styled.div`
  text-align: center;
  font-size: 24px;
  color: #ff6347; /* 강조되는 색상 (토마토) */
  font-weight: bold;
  margin: 50px 0;
  padding: 20px;
  background-color: rgba(255, 99, 71, 0.1); /* 배경 색상 */
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 2px dashed #ff6347; /* 대시선으로 강조 */
  font-size: 30px; /* 크기 조정 */
  text-transform: uppercase; /* 대문자로 강조 */
  letter-spacing: 2px; /* 글자 간격 */
`;

// 계정 정보 스타일
export const AccountInfo = styled.div`
  margin-top: 30px;
  background-color: #f4f4f9;
  border: 1px solid #ddd;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    color: #2f4f4f; /* Dark Slate Gray 색상 */
    font-weight: bold;
  }

  p {
    font-size: 16px;
    color: #333;
  }

  .account-details {
    margin-top: 15px;
    font-weight: bold;
    color: #555;
  }
`;

export const LogoDiv = styled.div`
  margin-bottom: 50px;
`;
