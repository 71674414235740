import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PATH } from './constants/path';
import Main from './pages/main/main';
import MainBoard from './pages/mainboard/mainBoard';
import Dashboard from './pages/mainboard/dashboard';
import SignupPage from './pages/register/SignupPage';
import AdminLogin from './pages/admin/admin-login/adminLogin';
import RecordHistory from './pages/mainboard/record_history';
import TrackHistory from './pages/mainboard/track_history';
import Report from './pages/mainboard/report_generation';
import ProductVendor from './pages/mainboard/product_vendor';
import Settings from './pages/mainboard/settings';
import InsertData from './pages/mainboard/insertdata';
import SearchData from './pages/mainboard/searchdata';

const Router = () => {
  const router = createBrowserRouter([
    {
      path: PATH.MAIN,
      element: <Main />,
    },
    {
      path: PATH.MAINBOARD,
      element: <MainBoard />,
      children: [
        { path: PATH.DASHBOARD, element: <Dashboard /> },
        { path: PATH.InsertData, element: <InsertData /> },
        { path: PATH.SearchData, element: <SearchData /> },
        { path: PATH.RECORD_HISTORY, element: <RecordHistory /> },
        { path: PATH.TRACK_HISTORY, element: <TrackHistory /> },
        { path: PATH.REPORT, element: <Report /> },
        { path: PATH.PRODUCT_VENDOR, element: <ProductVendor /> },
        { path: PATH.SETTINGS, element: <Settings /> },
      ],
    },
    { path: PATH.REGISTER, element: <SignupPage /> },
    { path: PATH.ADMINLOGIN, element: <AdminLogin /> },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
