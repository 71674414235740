import React from 'react';
import Main from '../../components/main/Main';
import ConnectWallet from 'src/components/walletbtn/ConnectWallet';
import { AccountInfo, MainContainer, LogoDiv, ConnectWalletDiv } from './main.style';

const MainPage: React.FC = () => {
  return (
    <MainContainer>
      {/* 메인 로고 */}
      <LogoDiv>
        <Main /> {/* Main 컴포넌트가 로고를 담당한다고 가정 */}
      </LogoDiv>

      {/* 로그인 버튼을 오른쪽 상단에 위치시킴 */}
      <ConnectWalletDiv>
        <ConnectWallet />
      </ConnectWalletDiv>

      {/* 계정 정보 안내 */}
      <AccountInfo>
        <h3>시연개발 테스트 계정 정보</h3>
        <div className="account-details">
          <p>
            계정 1: <strong>farmer@futuresense.co.kr</strong> / 비밀번호: <strong>1234</strong>
          </p>
          <p>
            계정 2: <strong>factory@futuresense.co.kr</strong> / 비밀번호: <strong>1234</strong>
          </p>
        </div>
      </AccountInfo>
    </MainContainer>
  );
};

export default MainPage;
